import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { PACKAGES } from "../../config/apiConfig";
import { Button, Pagination, Card, Table, Tabs } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import EmptyTable from "./EmptyTable";
import { IoMdAdd } from "react-icons/io";
import PackageForm from "../forms/PackageForm";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { formatCurrencyBRL } from "../../utils/utils";
import { FaArrowsLeftRight } from "react-icons/fa6";
import Loading from "../Loading";
import PackagePredefinedForm from "../forms/PackagePredefinedForm";
import ModalConfirmAdditionalPlan from "../modals/ModalConfirmAdditionalPlan";

const PackagesTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const [statusId, setStatusId] = useState(0);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenPredefined, setIsDrawerOpenPredefined] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: packages,
  } = useQuery(
    ["packages", currentPage, statusId],
    async () => {
      const { data: items } = await axios.get(
        `${PACKAGES.GET_PACKAGES}?page=${currentPage}&statusId=${statusId}`
      );
      setTotalPages(items.data.totalPages || 1);
      return items.data.data;
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  const handleAddPackage = () => {
    setSelectedPackage(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditPackage = (packageObj) => {
    setTypeDrawer("EDIT");
    setSelectedPackage(packageObj);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeletePackage = (customer) => {
    setSelectedPackage(customer);
    setOpenModalDelete(true);
  };

  const deletePackage = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${PACKAGES.GET_PACKAGE}/${selectedPackage.id}`);
      queryClient.invalidateQueries(["packages", currentPage]);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <div className="flex justify-end mb-3 mt-2 items-center gap-3">
        {/* <Button
          className="bg-gray-700 ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddPackage}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Pacotes pré-definidos</span>
        </Button> */}
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddPackage}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Criar pacote</span>
        </Button>
      </div>
      <Card>
        <Tabs
          aria-label="Tabs with underline"
          variant="underline"
          onActiveTabChange={(tab) => setStatusId(tab)}
        >
          <Tabs.Item active title="Pendentes">
            {!isLoading ? (
              <>
                {packages?.length > 0 ? (
                  <>
                    <small
                      className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
                      style={{ fontSize: 10 }}
                    >
                      <span>Deslize para ver todos os detalhes do pacote</span>{" "}
                      <FaArrowsLeftRight />
                    </small>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Cliente
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data de criação
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data de expiração
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Valor total:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Ações:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {!isLoading ? (
                            <>
                              {packages.map((packageObj, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {packageObj.Customer?.name}
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {moment(packageObj.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {packageObj.hasExpirationDate
                                      ? moment(
                                          packageObj.expirationDate
                                        ).format("DD/MM/YYYY")
                                      : "Não expira"}
                                  </Table.Cell>
                                  {/* <Table.Cell className="text-center">
                                {packageObj.Command?.statusId === 1 ? (
                                  <Badge
                                    color="success"
                                    className="w-fit mx-auto"
                                  >
                                    Faturado
                                  </Badge>
                                ) : (
                                  <Badge className="w-fit mx-auto" color="gray">
                                    Pagamento pendente
                                  </Badge>
                                )}
                              </Table.Cell> */}
                                  <Table.Cell className="text-center">
                                    {formatCurrencyBRL.format(
                                      packageObj.totalPrice
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    <div className="flex gap-4 items-center justify-center">
                                      {packageObj?.Command?.statusId === 1 ? (
                                        <FiEye
                                          className="text-gray-500 text-xl mt-1 cursor-pointer"
                                          onClick={() =>
                                            handleEditPackage(packageObj)
                                          }
                                        />
                                      ) : (
                                        <Button
                                          className="cursor-pointer primary text-white shadow"
                                          size="xs"
                                          onClick={() =>
                                            handleEditPackage(packageObj)
                                          }
                                        >
                                          Pagar
                                        </Button>
                                      )}

                                      <Icon
                                        fontSize="small"
                                        className="color-primary cursor-pointer"
                                        onClick={() =>
                                          handleDeletePackage(packageObj)
                                        }
                                      >
                                        delete
                                      </Icon>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          ) : (
                            <>
                              {Array.from({ length: 12 }).map((_, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          )}
                        </Table.Body>
                      </Table>
                      {totalPages > 1 && (
                        <div className="flex overflow-x-auto justify-center mt-3">
                          <Pagination
                            layout="pagination"
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                            previousLabel=""
                            nextLabel=""
                            showIcons
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <EmptyTable icon="search_off">
                    Ops! Nenhum pacote pendente encontrado.
                  </EmptyTable>
                )}
              </>
            ) : (
              <Loading />
            )}
          </Tabs.Item>
          <Tabs.Item title="Ativos">
            {!isLoading ? (
              <>
                {packages?.length > 0 ? (
                  <>
                    <small
                      className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
                      style={{ fontSize: 10 }}
                    >
                      <span>Deslize para ver todos os detalhes do pacote</span>{" "}
                      <FaArrowsLeftRight />
                    </small>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Cliente
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data de criação
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data de expiração
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Valor total:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Ações:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {!isLoading ? (
                            <>
                              {packages.map((packageObj, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {packageObj.Customer?.name}
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {moment(packageObj.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {packageObj.hasExpirationDate
                                      ? moment(
                                          packageObj.expirationDate
                                        ).format("DD/MM/YYYY")
                                      : "Não expira"}
                                  </Table.Cell>
                                  {/* <Table.Cell className="text-center">
                                {packageObj.Command?.statusId === 1 ? (
                                  <Badge
                                    color="success"
                                    className="w-fit mx-auto"
                                  >
                                    Faturado
                                  </Badge>
                                ) : (
                                  <Badge className="w-fit mx-auto" color="gray">
                                    Pagamento pendente
                                  </Badge>
                                )}
                              </Table.Cell> */}
                                  <Table.Cell className="text-center">
                                    {formatCurrencyBRL.format(
                                      packageObj.totalPrice
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    <div className="flex gap-4 items-center justify-center">
                                      {packageObj?.Command?.statusId === 1 ? (
                                        <FiEye
                                          className="text-gray-500 text-xl mt-1 cursor-pointer"
                                          onClick={() =>
                                            handleEditPackage(packageObj)
                                          }
                                        />
                                      ) : (
                                        <Button
                                          className="cursor-pointer primary text-white shadow"
                                          size="xs"
                                          onClick={() =>
                                            handleEditPackage(packageObj)
                                          }
                                        >
                                          Pagar
                                        </Button>
                                      )}

                                      <Icon
                                        fontSize="small"
                                        className="color-primary cursor-pointer"
                                        onClick={() =>
                                          handleDeletePackage(packageObj)
                                        }
                                      >
                                        delete
                                      </Icon>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          ) : (
                            <>
                              {Array.from({ length: 12 }).map((_, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    <div
                                      role="status"
                                      className="max-w-sm animate-pulse inline"
                                    >
                                      <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          )}
                        </Table.Body>
                      </Table>
                      {totalPages > 1 && (
                        <div className="flex overflow-x-auto justify-center mt-3">
                          <Pagination
                            layout="pagination"
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                            previousLabel=""
                            nextLabel=""
                            showIcons
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <EmptyTable icon="search_off">
                    Ops! Nenhum pacote ativo encontrado.
                  </EmptyTable>
                )}
              </>
            ) : (
              <Loading />
            )}
          </Tabs.Item>
        </Tabs>
      </Card>

      <ModalConfirmAdditionalPlan />

      <Drawer
        title={`${
          typeDrawer === "CREATE" ? "Cadastrar novo pacote" : "Editar pacote"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <PackageForm
          packageObj={selectedPackage}
          fetchPackages={() =>
            queryClient.invalidateQueries(["packages", currentPage])
          }
          onClose={setIsDrawerOpen}
          type={typeDrawer}
          isDrawerOpen={isDrawerOpen}
        />
      </Drawer>

      <Drawer
        title={`${
          typeDrawer === "CREATE"
            ? "Cadastrar novo modelo de pacote"
            : "Editar modelo de pacote"
        }`}
        onClose={setIsDrawerOpenPredefined}
        isOpen={isDrawerOpenPredefined}
        className="w-full md:w-3/6 pb-36"
      >
        <PackagePredefinedForm
          packageObj={selectedPackage}
          fetchPackages={() =>
            queryClient.invalidateQueries(["packages", currentPage])
          }
          onClose={setIsDrawerOpenPredefined}
          type={typeDrawer}
          isDrawerOpen={isDrawerOpenPredefined}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deletePackage}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir esse pacote?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default PackagesTable;
