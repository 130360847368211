let BASE_URL = "";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://app.relinq.com.br";
}

BASE_URL += "/api";

const ADDITIONAL_PLANS = {
  GET_ALL: "/additional-plans",
  GET_ONE: "/additional-plan",
};

const APPOINTMENTS = {
  GET_APPOINTMENTS: "/appointments",
  GET_APPOINTMENT: "/appointment",
  GET_CANCELLATIONS: "/cancellations",
};

const COMMANDS = {
  GET_COMMANDS: "/commands",
  GET_COMMAND: "/command",
  GET_INVOICES: "/invoices",
};

const CATEGORIES = {
  GET_CATEGORIES: "/categories",
  GET_CATEGORY: "/category",
};

const COMPANIES = {
  GET_COMPANIES: "/companies",
  GET_COMPANY: "/company",
  GET_ADDITIONAL_PLANS: "/company/plans/additional",
  UPDATE_COMPANY: "/company/support",
  UPDATE_FIRST_ACCESS: "/first-access",
};

const CUSTOMERS = {
  GET_CUSTOMERS: "/customers",
  GET_CUSTOMER: "/customer",
};

const FORMS = {
  GET_FORMS: "/forms",
  GET_FORM: "/form",
};

const LOGIN = {
  POST_LOGIN: BASE_URL + "/login",
};

const NOTIFICATIONS = {
  GET_ALL: "/notifications",
  GET: "/notifications/alert",
};

const PACKAGES = {
  GET_PACKAGES: "/packages",
  GET_PACKAGE: "/package",
  GET_BY_CUSTOMER: "/packages/customer",
};

const PAYMENTS = {
  GET_PAYMENTS: "/payments",
  NEW_SIGNATURE: "/new-signature",
  NEW_SIGNATURE_PIX: "/new-signature-pix",
};

const PROFESSIONALS = {
  GET_PROFESSIONALS: "/professionals",
  GET_PROFESSIONAL: "/professional",
};

const PRODUCTS = {
  GET_PRODUCTS: "/products",
  GET_PRODUCT: "/product",
  GET_OUTPUTS: "/product/outputs",
  STOCK: "/product/stock",
};

const RECORDS = {
  GET_RECORDS: "/records",
  GET_RECORD: "/record",
};

const REVIEWS = {
  GET_ALL: "/reviews",
  GET_BY_PROFESSIONALS: "/reviews/professionals",
};

const SERVICES = {
  GET_SERVICES: "/services",
  GET_SERVICE: "/service",
};

const AVATAR = {
  UPLOAD: "/avatar/upload",
};

export {
  ADDITIONAL_PLANS,
  APPOINTMENTS,
  COMMANDS,
  COMPANIES,
  BASE_URL,
  CATEGORIES,
  CUSTOMERS,
  FORMS,
  LOGIN,
  NOTIFICATIONS,
  PACKAGES,
  PAYMENTS,
  PROFESSIONALS,
  PRODUCTS,
  RECORDS,
  REVIEWS,
  SERVICES,
  AVATAR,
};
